import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import * as Icon from 'react-bootstrap-icons';

import US from './US';
import SectionHeader from '../SectionHeader/SectionHeader';
import Certifications from '../Certifications/Certifications';

import Teamwork from '../../images/aboutus/teamwork-1.png';
import Mission from '../../images/aboutus/mission-1.png';
import Vision from '../../images/aboutus/vision-1.png';
import Goal from '../../images/aboutus/goal-1.png';

import Honesty from '../../images/aboutus/honesty-1.png';
import Respect from '../../images/aboutus/respect-1.png';
import Responsibility from '../../images/aboutus/responsibility-1.png';
import Discipline from '../../images/aboutus/discipline-1.png';
import Commitment from '../../images/aboutus/commitment-1.png';
import Innovation from '../../images/aboutus/innovation-1.png';

import Idea from '../../images/aboutus/idea-1.png';
import SocialResponsibility from '../../images/aboutus/social-responsability-1.png';
import CallCenter from '../../images/aboutus/call-center-1.png';
import Team from '../../images/aboutus/team-1.png';
import Nosotros from '../../images/aboutus/nosotros.jpg'


const AboutUs = () => {
    const usHeaderParams = {
        smallTitle: 'Nosostros somos',
        bigTitle: 'Cajiga & Asociados Abogados',
    }

    const missionHeaderParams = {
        smallTitle: 'Nuestra',
        bigTitle: 'Misión',
    }

    const visionHeaderParams = {
        smallTitle: 'Nuestra',
        bigTitle: 'Visión',
    }

    const goalsHeaderParams = {
        smallTitle: 'Nuestros',
        bigTitle: 'Objetivos',
    }

    const valuesHeaderParams = {
        smallTitle: 'Nuestros',
        bigTitle: 'Valores',
        bigTitleColor: 'whiteTitle'
    }

    const biginningHeaderParams = {
        smallTitle: 'Nuestros',
        bigTitle: 'Principios',
    }

    return (
        <Container fluid className='pl-0 pr-0 zoom-img' style={{paddingTop: '10%'}}>
            <Row className='m-0 mb-5 justify-content-center'>
                <Col lg={8} md={9} sm={12} xs={12}>
                    <US header={usHeaderParams} />
                </Col>
                <Col className='d-flex justify-content-center align-items-center'>
                    <img src={Nosotros} alt='icon' height={200} />
                </Col>
            </Row>
            <Row className='m-0'>
                <Col>
                    <Tabs defaultActiveKey="mission">
                        <Tab eventKey="mission" title="Misión">
                            <Container fluid className='mt-4 mb-5 text-center'>
                                <Row className='justify-content-center'>
                                    <Col className='d-flex justify-content-center align-items-center'>
                                        <img src={Mission} alt='icon' height={200} />
                                    </Col>
                                    <Col lg={9} md={9} sm={12} xs={12}>
                                        <Container>
                                            <Row>
                                                <Col className='mt-5 mb-5'>
                                                    <SectionHeader {...missionHeaderParams} />
                                                </Col>
                                            </Row>
                                            <Row className='p-3'>
                                                <Col className='text-justify'>
                                                    <p>Consolidarnos como una empresa líder en el país en la recuperación de cartera vencida, integrando el crecimiento profesional de nuestro personal alineado a una cultura de trabajo y resultados que superen las expectativas de nuestros clientes.</p>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        <Tab eventKey="vision" title="Visión">
                            <Container fluid className='mt-4 mb-5 text-center'>
                                <Row className='justify-content-center'>
                                    <Col className='d-flex justify-content-center align-items-center'>
                                        <img src={Vision} alt='icon' height={200} />
                                    </Col>
                                    <Col lg={9} md={9} sm={12} xs={12}>
                                        <Container>
                                            <Row>
                                                <Col className='mt-5 mb-5'>
                                                    <SectionHeader {...visionHeaderParams} />
                                                </Col>
                                            </Row>
                                            <Row className='p-2'>
                                                <Col className='text-justify'>
                                                    <p>Ser un Despacho Líder de Cobranza Extrajudicial, Judicial y de Litigio, reconocido en el mercado a nivel nacional, ofreciendo un servicio con base en nuestra sinergia integrada por:</p>
                                                    <ul className='p-0' style={{ listStyleType: 'none' }}>
                                                        <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-2' />Experiencia</li>
                                                        <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-2' />Profesionalismo</li>
                                                        <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-2' />Ética</li>
                                                        <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-2' />Innovación tecnológica</li>
                                                        <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-2' />Seguridad en la información</li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        <Tab eventKey="goals" title="Objetivos">
                            <Container fluid className='p-0 mt-4 mb-5'>
                                <Row>
                                    <Col className='d-flex justify-content-center align-items-center'>
                                        <img src={Goal} alt='icon' height={200} />
                                    </Col>
                                </Row>
                                <Row className='justify-content-center'>
                                    <Col className='p-0'>
                                        <Container fluid>
                                            <Row>
                                                <Col className='mt-5 mb-5'>
                                                    <SectionHeader {...goalsHeaderParams} />
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-center m-0 p-0'>
                                                <Col lg={4} md={6} sm={6} xs={12}>
                                                    <Card className='mb-4 zoom-sm shadow' style={{ borderRadius: '1rem', height: '260px' }}>
                                                        <Card.Body>
                                                            <Card.Title><Icon.Bullseye style={{ color: "#41b7c4" }} /></Card.Title>
                                                            <Card.Text><p>Ajustar nuestros servicios con base a las necesidades de nuestros clientes, ofreciéndole un resultado que supere sus expectativas.</p></Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col lg={4} md={6} sm={6} xs={12}>
                                                    <Card className='mb-4 zoom-sm shadow' style={{ borderRadius: '1rem', height: '260px' }}>
                                                        <Card.Body>
                                                            <Card.Title><Icon.Bullseye style={{ color: "#41b7c4" }} /></Card.Title>
                                                            <Card.Text><p>Otorgar un servicio transparente con la mejor calidad a través del seguimiento al Código de Ética, políticas, procedimientos y estrategias específicas, protegiendo la seguridad de la información en forma absoluta de nuestros clientes.</p></Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col lg={4} md={6} sm={6} xs={12}>
                                                    <Card className='mb-4 zoom-sm shadow' style={{ borderRadius: '1rem', height: '260px' }}>
                                                        <Card.Body>
                                                            <Card.Title><Icon.Bullseye style={{ color: "#41b7c4" }} /></Card.Title>
                                                            <Card.Text><p>Buscar una mejora constante a través de actualizaciones, capacitación e innovación tecnológica para que el Despacho sobresalga de nuestra competencia en la recuperación de cartera vencida.</p></Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
            <Row className='m-0 mb-5'>
                <Col className='p-0'>
                    <Container fluid className='p-3' style={{ backgroundColor: '#1b2642' }}>
                        <Row>
                            <Col className='mt-5 mb-5'>
                                <SectionHeader {...valuesHeaderParams} />
                            </Col>
                        </Row>
                        <Row className='m-0 mb-5 justify-content-center text-white'>
                            <Col lg={2} md={3} sm={3} xs={6} className='text-center p-3'>
                                <Container fluid>
                                    <Row>
                                        <Col className='d-flex justify-content-center'>
                                            <div className='d-flex justify-content-center align-items-center rounded-circle bg-white' style={{ width: 100, height: 100 }}>
                                                <img src={Honesty} alt='icon' height={100} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p class='mt-3'>Honestidad</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col lg={2} md={3} sm={3} xs={6} className='text-center p-3'>
                                <Container fluid>
                                    <Row>
                                        <Col className='d-flex justify-content-center'>
                                            <div className='d-flex justify-content-center align-items-center rounded-circle bg-white' style={{ width: 100, height: 100 }}>
                                                <img src={Respect} alt='icon' height={100} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p class='mt-3'>Respeto</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col lg={2} md={3} sm={3} xs={6} className='text-center p-3'>
                                <Container fluid>
                                    <Row>
                                        <Col className='d-flex justify-content-center'>
                                            <div className='d-flex justify-content-center align-items-center rounded-circle bg-white' style={{ width: 100, height: 100 }}>
                                                <img src={Responsibility} alt='icon' height={100} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p class='mt-3'>Responsabilidad</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col lg={2} md={3} sm={3} xs={6} className='text-center p-3'>
                                <Container fluid>
                                    <Row>
                                        <Col className='d-flex justify-content-center'>
                                            <div className='d-flex justify-content-center align-items-center rounded-circle bg-white' style={{ width: 100, height: 100 }}>
                                                <img src={Discipline} alt='icon' height={100} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p class='mt-3'>Disciplina</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col lg={2} md={3} sm={3} xs={6} className='text-center p-3'>
                                <Container fluid>
                                    <Row>
                                        <Col className='d-flex justify-content-center'>
                                            <div className='d-flex justify-content-center align-items-center rounded-circle bg-white' style={{ width: 100, height: 100 }}>
                                                <img src={Commitment} alt='icon' height={100} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p class='mt-3'>Compromiso</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col lg={2} md={3} sm={3} xs={6} className='text-center p-3'>
                                <Container fluid>
                                    <Row>
                                        <Col className='d-flex justify-content-center'>
                                            <div className='d-flex justify-content-center align-items-center rounded-circle bg-white' style={{ width: 100, height: 100 }}>
                                                <img src={Innovation} alt='icon' height={100} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p class='mt-3'>Innovación</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row className='m-0 mb-5'>
                <Col className='p-0'>
                    <Container fluid className='p-3'>
                        <Row>
                            <Col className='mt-5 mb-5'>
                                <SectionHeader {...biginningHeaderParams} />
                            </Col>
                        </Row>
                        <Row className='m-0 mb-5 justify-content-center'>
                            <Col lg={3} md={3} sm={3} xs={6} className='text-center p-3'>
                                <Container fluid>
                                    <Row>
                                        <Col className='d-flex justify-content-center'>
                                            <div className='d-flex justify-content-center align-items-center rounded-circle' style={{ width: 100, height: 100, backgroundColor: '#1b2642' }} >
                                                <img src={Idea} alt='icon' height={110} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p class='mt-3'>Cultura de resultados con calidad</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col lg={3} md={3} sm={3} xs={6} className='text-center p-3'>
                                <Container fluid>
                                    <Row>
                                        <Col className='d-flex justify-content-center'>
                                            <div className='d-flex justify-content-center align-items-center rounded-circle' style={{ width: 100, height: 100, backgroundColor: '#1b2642' }} >
                                                <img src={SocialResponsibility} alt='icon' height={100} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p class='mt-3'>Responsabilidad Social</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col lg={3} md={3} sm={3} xs={6} className='text-center p-3'>
                                <Container fluid>
                                    <Row>
                                        <Col className='d-flex justify-content-center'>
                                            <div className='d-flex justify-content-center align-items-center rounded-circle' style={{ width: 100, height: 100, backgroundColor: '#1b2642' }} >
                                                <img src={CallCenter} alt='icon' height={100} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p class='mt-3'>Excelencia en el Servicio</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col lg={3} md={3} sm={3} xs={6} className='text-center p-3'>
                                <Container fluid>
                                    <Row>
                                        <Col className='d-flex justify-content-center'>
                                            <div className='d-flex justify-content-center align-items-center rounded-circle' style={{ width: 100, height: 100, backgroundColor: '#1b2642' }} >
                                                <img src={Team} alt='icon' height={100} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p class='mt-3'>Desarrollo del Capital humano</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row className='m-0 mb-5 d-flex justify-content-center' style={{backgroundColor: '#1b2642'}}>
                <Col lg={6} md={6} sm={12} xs={12} className='m-0 p-0'>
                    <Certifications />
                </Col>
            </Row>
        </Container>
    );
}

export default AboutUs;
