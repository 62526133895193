import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

function PrivacyNoticeModal(props) {
    let url = encodeURIComponent(`${window.location.origin}/AvisoDePrivacidad.pdf`);

    return (
        <Modal
            {...props}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Header closeButton>
                <Modal.Title>Aviso de privacidad</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid className='p-0' style={{ height: '50vh' }}>
                    <embed className='w-100 h-100' src={`${process.env.PUBLIC_URL}/pdfjs-dist/web/viewer.html?file=${url}`} />
                </Container>
            </Modal.Body>
        </Modal>
    );
}

function PrivacyNotice() {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <Button variant='outline-info' size='sm' onClick={() => setModalShow(true)}>Aviso de privacidad</Button>
            <PrivacyNoticeModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

class Footer extends React.Component {

    render() {
        let year = new Date().getFullYear();

        return (
            <Container fluid className='bg-dark p-3 small text-center text-white'>
                <Row>
                    <Col className='pb-3'>
                        {year} ® Todos los derechos reservados: Cajiga & Asociados Abogados
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PrivacyNotice />
                    </Col>
                </Row>
                <Row className='p-0 text-left'>
                    <Col className='p-0 pl-1 m-0'>
                        <Accordion className='attributions'>
                            <Accordion.Toggle as={Button} variant='link' size='sm' eventKey='0' className='p-0 m-0 text-muted font-weight-lighter'>Atribuciones</Accordion.Toggle>
                            <Accordion.Collapse eventKey='0' className='pt-5'>
                                <>
                                    <div>Icons made by <a href='https://www.freepik.com' target='_blank' rel='noreferrer' title='Freepik'>Freepik</a> from <a href='https://www.flaticon.com/' target='_blank' rel='noreferrer' title='Flaticon'>www.flaticon.com</a></div>
                                    <div>Icons made by <a href='https://www.flaticon.com/authors/surang' target='_blank' rel='noreferrer' title='surang'>surang</a> from <a href='https://www.flaticon.com/' target='_blank' rel='noreferrer' title='Flaticon'>www.flaticon.com</a></div>
                                </>
                            </Accordion.Collapse>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Footer;
