import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import "./Commentary.css";
import Hombre from '../../images/commentary/jefe.png'
import Mujer from '../../images/commentary/mujer.png'
const sliders = [
    {
        caption: {
            img: Hombre,
            title: '“Me agrada la nueva página web, muchas felicidades por esta iniciativa.”',
            desc: 'Anònimo 1'
        }
    },
    {
        caption: {
            img: Hombre,
            title: '“Me encanta la nueva imagen de Cajiga & Asociados, es mucho más intuitiva”',
            desc: 'Anònimo 2'
        }
    },
    {
        caption: {
            img: Mujer,
            title: '“Me ayudo a resolver mi problema, gracias por su apoyo”',
            desc: 'Anònimo 3'
        }
    },
];

class Commentary extends React.Component {
    render() {
        return (
            <Carousel>
                {sliders.map((value, index) => (
                    <Carousel.Item interval={3000} key={`slider_${index}`} className="commentaryContainer" >
                        <Carousel.Caption className="txtCommentary">
                            <img src={value.caption.img} alt='img' />
                            <h3>{value.caption.title}</h3>
                            <p>{value.caption.desc}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
        );
    }
}

export default Commentary;