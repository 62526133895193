import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';

import './SectionHeader.css';

const SectionHeader = props => {
    const { smallTitle, bigTitle, smallTitleColor, bigTitleColor, align } = props;

    return (
        <Container fluid className={`${align}`}>
            {smallTitle ? <h5 className={smallTitleColor}>{smallTitle.toUpperCase()}</h5> : <></>}
            {bigTitle ? <h1 className={bigTitleColor}>{bigTitle.toUpperCase()}</h1> : <></>}
        </Container>
    )
}

SectionHeader.defaultProps = {
    smallTitle: '',
    bigTitle: '',
    smallTitleColor: 'greenTitle',
    bigTitleColor: 'blackTitle',
    align: 'text-center',
}

SectionHeader.propTypes = {
    smallTitle: PropTypes.string,
    bigTitle: PropTypes.string
}

export default SectionHeader;

