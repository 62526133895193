import React from 'react';

import Carousel from '../Carousel/Carousel';
import SpecialtyAreas from '../SpecialtyAreas/SpecialtyAreas';
import CarouselClient from '../Carousel/CarouselClient';
import Experience from '../Experience/Experience';
import Commentary from '../Commentary/Commentary';

const Home = props => {
    return (
        <>
            <Carousel />
            <SpecialtyAreas />
            <CarouselClient />
            <Experience />
            <Commentary />
        </>
    )
}

export default Home;