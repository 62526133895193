import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SectionHeader from '../SectionHeader/SectionHeader';

const US = props => {
    const { header } = props;

    return (
        <Container fluid>
            <Row>
                <Col className='m-0 p-0' >
                    <Container>
                        <Row>
                            <Col>
                                <SectionHeader {...header} />
                            </Col>
                        </Row>
                        <Row className='p-3'>
                            <Col>
                                <p>Más de <b className='h5'>32 años</b> de experiencia nos respaldan</p>
                            </Col>
                        </Row>
                        <Row className='p-3'>
                            <Col className='text-justify'>
                                <p>Somos una empresa mexicana fundada en 1987 que nace con el propósito de ofrecer servicios de recuperación de cartera vencida en todas sus etapas a empresas nacionales y extranjeras, con un grupo de profesionales de amplia experiencia en litigio y administración de carteras buscando ser una solución para sus clientes con los más altos estándares de calidad.</p>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}

export default US;