import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as Icon from 'react-bootstrap-icons';
import * as emailjs from 'emailjs-com'
import Modal from 'react-bootstrap/Modal';

import SectionHeader from '../SectionHeader/SectionHeader';

class ContactMail extends React.Component {
    constructor() {
        super();
        this.state = {
            name: '',
            email: '',
            tel: '',
            message: '',
            show: false,
            titleModal: '',
            messageModal: '',
            charters: 0,
        }
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    showModal = () => {
        this.setState({ show: true });
    };
    
      hideModal = () => {
        this.setState({ show: false });
    };
      
    countChars = () => {
        const { message } = this.state
        this.setState({ charters: message.length });
    }

    handleSubmit = async (e) => {
            e.preventDefault()
            const { name, email, tel, message } = this.state
            let templateParams = {
            from_name: name,
            mail: email,
            tel: tel,
            message: message,
            }
    
            const result = await emailjs.send(
            'service_jbh3xan',
            'template_4niz6lr',
            templateParams,
            'user_K4xSaUmyTafkEK5Gy4v18'
            ).then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                if(response.status == 200) {
                    this.setState({titleModal: 'Mensaje enviado'})
                    this.setState({messageModal:'Tu mensaje se envió con éxito, en breve nos pondremos en contacto contigo.'})
                    this.showModal();
                } else {
                    this.setState({messageModal: 
                        <div className="list-contact">
                            No te preocupes tenemos más opciones para que nos contactes. <br></br><br></br>
                            <ul>
                                <li><a href="tel:5567190806"><Icon.Telephone/> (55) 6719 0806</a></li>
                                <li><a href="mailto:atencion.cliente@cajiga-asociados.com.mx"><Icon.Envelope/> atencion.cliente@cajiga-asociados.com.mx</a></li>
                            </ul>
                        </div>
                    })
                    this.showModal();
                }                
              })
              .catch((err) => {
                this.setState({titleModal: 'No pudimos entregar tu mensaje'})
                this.setState({messageModal: 
                    <div className="list-contact">
                        No te preocupes tenemos más opciones para que nos contactes. <br></br><br></br>
                        <ul>
                            <li><a href="tel:5567190806"><Icon.Telephone/> (55) 6719 0806</a></li>
                            <li><a href="mailto:atencion.cliente@cajiga-asociados.com.mx"><Icon.Envelope /> atencion.cliente@cajiga-asociados.com.mx</a></li>                          
                        </ul>
                    </div>
                })
                this.showModal();
              });
           
            this.resetForm()
        }
    
        resetForm() {
            this.setState({
            name: '',
            email: '',
            tel: '',
            message: '',
            charters: 0
            })
        }
    
        handleChange = (param, e) => {
            this.setState({ [param]: e.target.value })
        }

        onHandleChangeNumeric = e => {
            let valu = e.target.value;
           
            if (!Number(valu)) {
            return;
            }
           
            this.setState({ [e.target.name]: valu });
        };
    
    render() {

        const headerParams = {
            smallTitle: 'Con gusto te atenderemos',
            bigTitle: 'CONTÁCTANOS',
            bigTitleColor: 'whiteTitle'
        }
        
        return(
            <div>
                <Modal
                    show={this.state.show} handleClose={this.hideModal}
                    dialogClassName="pt-6 mt-6"
                >
                    <Modal.Header>
                        <Modal.Title>
                           {this.state.titleModal}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.messageModal}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-info" onClick={this.hideModal}>CERRAR</Button>
                    </Modal.Footer>
                </Modal>
    
                <Container fluid style={{ backgroundColor: '#1b2642' }}>
                    <Row>
                        <Col className='mt-5 mb-5'>
                            <SectionHeader {...headerParams} />
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col lg={9} md={8} sm={7} xs={12} className='border mb-5' style={{  borderRadius: '1rem', backgroundColor: '#fff'  }}>
                            <Form className="p-3" onSubmit={this.handleSubmit.bind(this)}>
                                <Form.Group>
                                    <Row>
                                        <Container className="col-md-6">
                                            <Form.Label><Icon.Person /> Nombre*</Form.Label>
                                            <Form.Control 
                                                placeholder="Nombre completo" 
                                                id="name" 
                                                type="text" 
                                                name="name"
                                                value={this.state.name}
                                                onChange={this.handleChange.bind(this, 'name')}
                                                className="form-control"
                                                required/>
                                        </Container>
                                        <Container className="col-md-6">
                                            <Form.Label><Icon.Envelope /> E-mail*</Form.Label>
                                            <Form.Control 
                                                placeholder="tu@correo.com" 
                                                id="mail" 
                                                type="email" 
                                                className="form-control"
                                                name="email"
                                                value={this.state.email}
                                                onChange={this.handleChange.bind(this, 'email')}
                                                required />
                                        </Container>
                                    </Row>
                                </Form.Group>
                                <Form.Group>
                                    <Row>
                                        <div className="col-md-6">
                                            <Form.Label><Icon.Telephone/> Telefono</Form.Label>
                                            <Form.Control 
                                                placeholder="55 5555 5555"
                                                pattern="[0-9]+" 
                                                maxLength="10"
                                                id="tel" 
                                                type="text" 
                                                className="form-control"
                                                value={this.state.tel}
                                                onChange={this.onHandleChangeNumeric, this.handleChange.bind(this, 'tel')}
                                               />
                                        </div>
                                    </Row>                                            
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label><Icon.CardText/>  Mensaje</Form.Label>
                                    <Form.Control 
                                        placeholder="Ingresa un mensaje (opcional)" 
                                        id="message" 
                                        type="text" 
                                        className="form-control"
                                        maxLength="1000"
                                        as="textarea" 
                                        rows={5}   
                                        value={this.state.message}
                                        onKeyUp={this.countChars.bind(this, 'charters')}
                                        onChange={this.handleChange.bind(this, 'message')}
                                    />
                                    <p style={{textAlign:'right'}}>{this.state.charters}/1000</p>
                                </Form.Group>
                                <Button type="submit" className="btn btn-info submit "><Icon.ArrowBarUp /> Enviar</Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
  
}

export default ContactMail
