import React from "react";
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Banner1 from '../../images/carousel/banner1.jpg';
import Banner2 from '../../images/carousel/banner2.jpg';
import Banner3 from '../../images/carousel/banner3.jpg';
import * as Icon from 'react-bootstrap-icons';

import './Carousel.css'

const sliders = [

    {
        img: Banner1,
        caption: {
            title:  <div className="txtTitle">
                        <h3>EXPERIENCIA EN:</h3>
                    </div>,
            desc:   <div className="container-fluid txtDesc">
                        <div className="row row-cols-1">
                            <div className="col"><Icon.CheckCircleFill style= {{color:"#41b7c4"}} /> Recuperación de Cartera Vencida</div>
                            <div className="col"><Icon.CheckCircleFill style= {{color:"#41b7c4"}} /> Cobranza Domiciliaria</div>
                            <div className="col"><Icon.CheckCircleFill style= {{color:"#41b7c4"}} /> Recuperación de Automóviles</div>
                            <div className="col"><Icon.CheckCircleFill style= {{color:"#41b7c4"}} /> Litigio Mercantil e Hipotecario</div>
                            <div className="col"><Icon.CheckCircleFill style= {{color:"#41b7c4"}} /> Verificación Telefónica y Domiciliaria</div>
                        </div>
                    </div>,
            button: <div className="contenedorButtons">
                        <Link to="/contact"><Button variant='outline-light' className="m-2">PONTE EN CONTACTO</Button></Link>
                        <Link to="/services"><Button variant='info' className="m-2">CONOCE MÁS</Button></Link>
                    </div>
        }
    },
    {
        img: Banner2,
        caption: {
            title:  <div className="txtTitle">
                        <h3>NUESTROS PRINCIPIOS:</h3>
                    </div>,
            desc:  <div className="container-fluid txtDesc">
                        <div className="row row-cols-1">
                            <div className="col"><Icon.BookmarkFill style= {{color:"#41b7c4"}} /> Cultura de resultados con calidad</div>
                            <div className="col"><Icon.BookmarkFill style= {{color:"#41b7c4"}} /> Responsabilidad Social</div>
                            <div className="col"><Icon.BookmarkFill style= {{color:"#41b7c4"}} /> Excelencia en el Servicio</div>
                            <div className="col"><Icon.BookmarkFill style= {{color:"#41b7c4"}} /> Desarrollo del Capital Humano</div>
                        </div>
                    </div>,
            button: <div className="contenedorButtons">
                        <Link to="/about-us"><Button variant='info' className="m-2">CONÓCENOS</Button></Link>
                    </div>
        }
    },
    {
        img: Banner3,
        caption: {
            title:  <div className="txtTitle">
                        <h3>NUESTROS VALORES:</h3>
                    </div>,
            desc:     <div className="container-fluid txtDesc">
                        <div className="row row-cols-1">
                            <div className="col"><Icon.BookmarkStarFill style= {{color:"#41b7c4"}} /> Honestidad</div>
                            <div className="col"><Icon.BookmarkStarFill style= {{color:"#41b7c4"}} /> Respeto</div>
                            <div className="col"><Icon.BookmarkStarFill style= {{color:"#41b7c4"}} /> Responsabilidad</div>
                            <div className="col"><Icon.BookmarkStarFill style= {{color:"#41b7c4"}} /> Disciplina</div>
                            <div className="col"><Icon.BookmarkStarFill style= {{color:"#41b7c4"}} /> Compromiso</div>
                            <div className="col"><Icon.BookmarkStarFill style= {{color:"#41b7c4"}} /> Innovación</div>
                        </div>
                    </div>,
            button: ''
        }
    },

];

class CarouselBanner extends React.Component {

    render() {

        return (
            <>
                <Carousel className="prueba">
                    {sliders.map((value, index) => (
                        <Carousel.Item interval={3000} key={`slider_${index}`}>
                            <div className='transbox'>
                                <img className='d-block w-100' src={value.img} alt='img' />
                                <Carousel.Caption className="txt-carousel-caption">
                                    {value.caption.title}
                                    {value.caption.desc}
                                    {value.caption.button}
                                </Carousel.Caption>
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </>
        );
    }
}

export default CarouselBanner;