import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import certification from '../../images/footerpage/excelencia.png'
import mexico from '../../images/footerpage/mexico.png'
import grafica from '../../images/footerpage/grafica.png'
import seguridad from '../../images/footerpage/seguridad.png'
import * as Icon from 'react-bootstrap-icons';

import "./FooterPage.css";

const FooterPage = () => {

  return (
    <Container fluid className="text-white small pt-4 mt-4 zoom-img" style={{ backgroundColor: '#374059' }}>
      <Row>
        <Col lg={3} md={6} sm={12} xs={12} className='mb-3'>
          <Container fluid>
            <Row className='text-center'>
              <Col>
                <img src={certification} alt='img' height={60} />
              </Col>
            </Row>
            <Row className='pt-3 pb-3 text-center'>
              <Col>
                <h5>ÁREAS DE ESPECIALIDAD</h5>
              </Col>
            </Row>
            <Row>
              <Col className='d-flex justify-content-center p-0'>
                <ul className='options-list' style={{ listStyleType: 'none', listStylePosition: 'outside' }}>
                  <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-1' /><p>Recuperación de Cartera Vencida</p></li>
                  <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-1' /><p>Cobranza Domiciliaria</p></li>
                  <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-1' /><p>Recuperación de Automóviles y daciones en pago</p></li>
                  <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-1' /><p>Litigio Mercantil e Hipotecario</p></li>
                  <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-1' /><p>Verificación Telefónica y Domiciliaria</p></li>
                  <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-1' /><p>Encuestas</p></li>
                  <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-1' /><p>Llamadas de Bienvenida</p></li>
                  <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-1' /><p>SMS Masivos</p></li>
                  <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-1' /><p>Llamadas automáticas (Blaster)</p></li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col lg={3} md={6} sm={12} xs={12} className='mb-3'>
          <Container fluid>
            <Row className='text-center'>
              <Col>
                <img src={mexico} alt='img' height={60} />
              </Col>
            </Row>
            <Row className='pt-3 pb-3 text-center'>
              <Col className='text-center'>
                <h5>COBERTURA NACIONAL</h5>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <p>Nuestra oficina Matriz se encuentra ubicada en la Ciudad de México, y contamos con cobertura telefónica a nivel nacional y domiciliaria en las ciudades de:</p>
              </Col>
            </Row>
            <Row>
              <Col className='d-flex justify-content-center p-0'>
                <ul className='p-0 options-list' style={{ listStyleType: 'none', listStylePosition: 'outside' }}>
                  <li><Icon.CursorFill style={{ color: "#41b7c4" }} className='m-1' /><p>Guadalajara</p></li>
                  <li><Icon.CursorFill style={{ color: "#41b7c4" }} className='m-1' /><p>Querétaro</p></li>
                  <li><Icon.CursorFill style={{ color: "#41b7c4" }} className='m-1' /><p>Puebla</p></li>
                  <li><Icon.CursorFill style={{ color: "#41b7c4" }} className='m-1' /><p>Monterrey</p></li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col lg={3} md={6} sm={12} xs={12} className='mb-3'>
          <Container fluid>
            <Row className='text-center'>
              <Col>
                <img src={grafica} alt='img' height={60} />
              </Col>
            </Row>
            <Row className='pt-3 pb-3 text-center'>
              <Col className='text-center'>
                <h5>SECTORES</h5>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <p>Contamos con una amplia variedad de clientes de diferentes sectores:</p>
              </Col>
            </Row>
            <Row>
              <Col className='d-flex justify-content-center p-0'>
                <ul className='p-0 options-list' style={{ listStyleType: 'none', listStylePosition: 'outside' }}>
                  <li><Icon.BookmarkFill style={{ color: "#41b7c4" }} className='m-1' /><p>Fintech</p></li>
                  <li><Icon.BookmarkFill style={{ color: "#41b7c4" }} className='m-1' /><p>Bancario</p></li>
                  <li><Icon.BookmarkFill style={{ color: "#41b7c4" }} className='m-1' /><p>Comercial</p></li>
                  <li><Icon.BookmarkFill style={{ color: "#41b7c4" }} className='m-1' /><p>Automotriz</p></li>
                  <li><Icon.BookmarkFill style={{ color: "#41b7c4" }} className='m-1' /><p>Gubernamental</p></li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col lg={3} md={6} sm={12} xs={12} className='mb-3'>
          <Container fluid>
            <Row className='text-center'>
              <Col>
                <img src={seguridad} alt='img' height={60} />
              </Col>
            </Row>
            <Row className='pt-3 pb-3 text-center'>
              <Col className='text-center'>
                <h5>SEGURIDAD</h5>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <p>Acceso restringido a personal, por medio de lectores biométricos que controlan:</p>
              </Col>
            </Row>
            <Row>
              <Col className='d-flex justify-content-center p-0'>
                <ul className='options-list' style={{ listStyleType: 'none', listStylePosition: 'outside' }}>
                  <li><Icon.ClockFill style={{ color: "#41b7c4" }} className='m-1' /><p>Horarios de ingreso y salida</p></li>
                  <li><Icon.ExclamationCircleFill style={{ color: "#41b7c4" }} className='m-1' /><p>Incidencias</p></li>
                  <li><Icon.Smartwatch style={{ color: "#41b7c4" }} className='m-1' /><p>Horas trabajadas</p></li>
                  <li><Icon.CameraVideoFill style={{ color: "#41b7c4" }} className='m-1' /><p>Sistema de circuito cerrado digital, con grabación continua las 24 horas.</p></li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default FooterPage;