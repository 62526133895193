import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Menu from './components/Menu/Menu.js';
import Home from './components/Home/Home';
import AboutUs from './components/AboutUs/AboutUs';
import PhotoGallery from './components/PhotoGallery/PhotoGallery.js';
import Services from './components/Services/Services';
import Contact from './components/Contact/Contact';
import FooterPage from './components/Footer/FooterPage.js'
import Footer from './components/Footer/Footer.js'
import NotFound from './components/NotFound/NotFound.js';

function App() {
  const [isNotFound, setNotFound] = useState(false);

  return (
    <Router>
      <Container fluid className='p-0'>
        {isNotFound ? <></> : <Menu />}
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/about-us' component={AboutUs} />
          <Route path='/services' component={Services} />
          <Route path='/contact' component={Contact} />
          <Route path='/photo-gallery' component={PhotoGallery} />
          <Route>
            <NotFound setNotFound={setNotFound} />
          </Route>
        </Switch>
        {
          isNotFound ? <></> :
            <>
              <FooterPage />
              <Footer />
            </>
        }
      </Container>
    </Router>
  );
}


export default App;
