import React from 'react'
import GoogleMap from 'google-map-react'

class MapUbication extends React.Component {

    render() {
      const renderMarkers = (map, maps) => {
          let marker = new maps.Marker({
          position: { lat: 19.389528, lng: -99.1706415 },
          map,
          title: 'Cajiga & Asociados Abogados',             
        });
          return marker;
      };
        return(
            <div style={{'width':'100%','height':400}}>
                <GoogleMap                      
                  bootstrapURLKeys={{key: 'AIzaSyBqu-DAHMO4YwcNLD3oobtczWLsdnB0RfY'}}
                  defaultZoom={15}
                  defaultCenter={{ 
                    lat: 19.389528, 
                    lng: -99.1706415
                }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
              />
            </div>
        );
    }
}

export default MapUbication;