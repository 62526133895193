import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import * as Icon from 'react-bootstrap-icons';

import SectionHeader from '../SectionHeader/SectionHeader';
import CallCenter from '../../images/specialtyareas/call-center.png';
import Administrative from '../../images/specialtyareas/handshake.png';
import Extrajudicial from '../../images/specialtyareas/extrajudicial.png';
import Judicial from '../../images/specialtyareas/judicial.png';

import './SpecialtyAreas.css';

const specialties = [
    {
        title: 'COBRANZA PREVENTIVA',
        desc: 'Se ejecuta mediante envío de mensajes de texto SMS, mensajes de Voz, correos electrónicos, inclusive llamadas telefónicas.',
        icon: CallCenter,
    },
    {
        title: 'COBRANZA ADMINISTRATIVA',
        desc: 'Proceso sistemático de evaluación, seguimiento y control de las cuentas por cobrar con el fin de lograr que los pagos se realicen de manera oportuna y efectiva.',
        icon: Administrative,
    },
    {
        title: 'COBRANZA EXTRAJUDICIAL',
        desc: 'Etapa previa a la cobranza judicial, ya que son todos los esfuerzos que realiza una empresa para cobrar su dinero a partir del primer día de mora del deudor.',
        icon: Extrajudicial,
    },
    {
        title: 'COBRANZA JUDICIAL',
        desc: 'Proceso que se realiza cuando un juicio de cobranza determina que se debe saldar una deuda.',
        icon: Judicial,
    },
]

const SpecialtyAreas = () => {
    const [toggle, setToggle] = useState(Icon.ChevronCompactDown);

    const multiple = 4;
    const headerParams = {
        smallTitle: 'DESPACHO DE ABOGADOS',
        bigTitle: 'ÁREAS DE ESPECIALIDAD'
    }

    let cardsRow = [];
    let cardsCol = [];
    for (let x = 0; x < specialties.length; x++) {
        let value = specialties[x];

        cardsCol.push(<Col key={`cardCol_${x}`} lg={3} md={6} sm={6} xs={12} >
            <Card className='mb-4 specialtyAreasContainer zoom shadow' style={{ borderRadius: '1rem' }}>
                <Card.Img variant='top' src={value.icon} className='iconimg' />
                <Card.Body>
                    <Card.Title>{value.title}</Card.Title>
                    <Card.Text>{value.desc}</Card.Text>
                </Card.Body>
            </Card>
        </Col>);

        let i = x + 1;
        if (i < specialties.length && (i % multiple === 0)) {
            cardsRow.push(<Row key={`cardRow_${i}`} className='justify-content-center'>{cardsCol.map(v => (v))}</Row>);
            cardsCol = [];
        } else if (i === specialties.length) {
            cardsRow.push(<Row key={`cardRow_${i}`} className='justify-content-center'>{cardsCol.map(v => (v))}</Row>);
            cardsCol = [];
        }
    }

    return (
        <Container fluid className='p-5 mb-5' style={{ backgroundColor: '#f9fbff' }}>
            <Accordion>
                <Row className='mt-5 mb-5'>
                    <Col>
                        <SectionHeader {...headerParams} />
                    </Col>
                </Row>
                {cardsRow.length > 0 ? cardsRow[0] : <></>}
                {cardsRow.length > 1 ?
                    <Row className='text-center'>
                        <Col>
                            <Accordion.Toggle as={toggle} size='50' fill='#41b7c4' eventKey='0'></Accordion.Toggle>
                        </Col>
                    </Row> :
                    <>
                        <Row className='text-center'>
                            <Col>
                                <Button as={Link} to='/services' variant='info' className="m-3">Conoce más</Button>
                            </Col>
                        </Row>
                    </>}
                {cardsRow.length > 1 ?
                    <>
                        <Accordion.Collapse onEnter={() => { setToggle(Icon.ChevronCompactUp) }} onExited={() => { setToggle(Icon.ChevronCompactDown) }} eventKey='0'>
                            <>
                                {cardsRow.map((value, index) => (index > 0 ? value : null))}
                            </>
                        </Accordion.Collapse>
                    </> : <></>
                }
            </Accordion>
        </Container>

    );
}

export default SpecialtyAreas;