import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Map from '../../components/Map/Map.js'
import Contact from '../../images/contact/Contacto.png';

import * as Icon from 'react-bootstrap-icons';
import './Contact.css'

import SectionHeader from '../SectionHeader/SectionHeader';

const ContactOther = props => {
    const { header } = props;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    return (
        <Container fluid className="zoom-img">
            
            <Modal
                size="lg"
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="pt-6 mt-6">
                <Modal.Header closeButton>
                    <Modal.Title>
                        ENCUENTRANOS
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Map />
                </Modal.Body>
            </Modal>
    
            <Row>
                <Col className='m-0 p-0' >
                    <Container>
                        <Row>
                            <Col className='mt-5'>
                                <SectionHeader {...header} />
                            </Col>
                        </Row>
                        <Row className='p-3'>
                            <Col>
                                <Container className="list-contact">
                                    <Card className="card-body-container">
                                        <Card.Body className="color-text-contact">
                                            <Card.Text>Teléfono</Card.Text>
                                                <ul>
                                                    <li><a href="tel:5510847110"><Icon.Telephone/> (55) 6719 0806</a></li>
                                                </ul>
                                            <Card.Text>Servicio a clientes</Card.Text>
                                                <ul >                          
                                                    <li><a href="mailto:atencion.cliente@cajiga-asociados.com.mx"><Icon.Envelope /> atencion.cliente@cajiga-asociados.com.mx</a></li>                          
                                                </ul>    
                                            <Card.Text>Dirección</Card.Text>
                                            <ul>
                                                <li>
                                                    <a onClick={() => setShow(true)} className="map-style"><Icon.Cursor/> Patricio Sanz 722 B, Col. Del Valle Alcaldía Benito Juarez, CDMX C.P. 03100</a>
                                                </li>
                                            </ul>
                                            <Card.Text>¿Quieres formar parte de nuestro equipo de trabajo?</Card.Text>
                                            <img className='imgJoinTeam' src={Contact} alt='img' />
                                            <br></br><br></br>
                                            <ul>
                                                Envíanos un correo electrónico a:
                                                <li><a href="mailto:recursos.humanos@cajiga-asociados.com.mx"><Icon.Envelope /> recursos.humanos@cajiga-asociados.com.mx</a></li>                          
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}

export default ContactOther;