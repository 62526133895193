import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Creditea from "../../images/carouselclient/creditea.png";
import Lendon from "../../images/carouselclient/lendon.png";
import Credifiel from "../../images/carouselclient/credifiel.jpeg";
import FinancieraE from "../../images/carouselclient/financieraequipat.jpeg";
import Exitus from "../../images/carouselclient/exitus.png";
import Moneyman from "../../images/carouselclient/moneyman.png";
import Dineria from "../../images/carouselclient/dineria.png";
import Kubo from "../../images/carouselclient/kubo.jpeg";
import Cream from "../../images/carouselclient/cream.png";
import Creditosi from "../../images/carouselclient/creditosi.png";
import Credifranco from "../../images/carouselclient/credifranco.png";
import Suauto from "../../images/carouselclient/suauto.jpeg";
import Fincomun from "../../images/carouselclient/fincomun.png";
import Stori from "../../images/carouselclient/stori.png";
import Famsa from "../../images/carouselclient/famsa.jpeg";
import Infonavit from "../../images/carouselclient/infonavit.jpeg";
import Santander from "../../images/carouselclient/santander.png";
import Santanderpymes from "../../images/carouselclient/santanderpymes.png";



import "./CarouselClient.css"


class CarouselClient extends React.Component {

    render() {
        const responsive = {
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: { max: 4000, min: 3000 },
              items: 5
            },
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1
            }
          };

          
        return (
          <div className='small clients'>
            <Carousel responsive={responsive}
              arrows={false}
              infinite={true}
              autoPlay={this.props.deviceType !== "mobile" ? true : false}
              autoPlaySpeed={10}
              transitionDuration={2000}
              centerMode={ true } 
              additionalTransfrom={10}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              >
                  <div className='bannerClient text-center'>
                      <img alt="" src={Creditea}/>
                      <br />
                      <img alt="" src={Lendon}/>
                  </div>                  
                  <div className='bannerClient text-center'>
                      <img alt="" src={Credifiel}/>
                      <br />
                      <img alt="" src={ Exitus }/>
                  </div>
                  <div className='bannerClient text-center'>
                      <img alt="" src={ Moneyman }/>
                      <br />
                      <img alt="" src={ Dineria }/>
                  </div>
                  <div className='bannerClient text-center'>
                      <img alt="" src={ Kubo }/>
                      <br />
                      <img alt="" src={ Creditosi }/>
                  </div>
                  <div className='bannerClient text-center'>
                      <img alt="" src={ Cream }/>
                      <br />
                      <img alt="" src={ Suauto }/>
                  </div>
                  <div className='bannerClient text-center'>
                    <img alt="" src={Credifranco}/>
                    <br />
                    <img alt="" src={ Fincomun }/>
                  </div>
                  <div className='bannerClient text-center'>
                      <img alt="" src={ Stori }/>
                      <br />
                      <img alt="" src={ Famsa }/>
                  </div>
                  <div className='bannerClient text-center'>
                      <img alt="" src={ FinancieraE }/>
                      <br />
                      <img alt="" src={ Infonavit }/>
                  </div>
                  <div className='bannerClient text-center'>
                      <img alt="" src={ Santander }/>
                      <br />
                      <img alt="" src={ Santanderpymes }/>
                  </div>
              </Carousel>
              <p className='font-weight-lighter text-center text-muted m-2'>*Todas las imagenes y logos son propiedad de sus respectivas marcas y son utilizados con fines ilustrativos.</p>
          </div>
           
        );
    }
}

export default CarouselClient;