import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import US from '../AboutUs/US';
import Certifications from '../Certifications/Certifications';

const Experience = () => {
    const headerParams = {
        smallTitle: 'Experiencia',
        bigTitle: 'Cajiga & Asociados Abogados',
        align: 'text-left',
    }

    return (
        <Container fluid className='mt-5 mb-5'>
            <Row>
                <Col lg={6} md={6} sm={12} xs={12} className='m-0 p-0 pt-5' >
                    <US header={headerParams} />
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className='m-0 p-0'>
                    <Certifications />
                </Col>
            </Row>
        </Container>
    );
}

export default Experience;