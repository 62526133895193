import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import * as Icon from 'react-bootstrap-icons';

import SectionHeader from '../SectionHeader/SectionHeader';

import iqnet from '../../images/certifications/iqnet.jpeg';
import sige from '../../images/certifications/sige.jpeg';

const Certifications = () => {
    const [toggle, setToggle] = useState(Icon.ChevronCompactDown);

    const headerParams = {
        smallTitle: 'EXCELENCIA EN EL SERVICIO',
        bigTitle: 'CERTIFICACIONES',
        bigTitleColor: 'whiteTitle'
    }

    return (
        <Container fluid style={{ backgroundColor: '#1b2642' }} className='zoom-img'>
            <Row>
                <Col className='mt-5 mb-5'>
                    <SectionHeader {...headerParams} />
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col lg={10} md={9} sm={8} xs={12} className='border mb-5 shadow-img' style={{ borderRadius: '1rem', backgroundColor: '#fff' }}>
                    <Accordion className='text-center'>
                        <Container fluid className='p-4'>
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12} className='text-center small'>
                                    <Container fluid className='p-0'>
                                        <Row>
                                            <Col className='p-4'>
                                                <img src={iqnet} alt='cert' className='rounded-circle shadow-img' style={{ height: '100px' }} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <h4>Certificados</h4>
                                                <h4>IQNet</h4>
                                            </Col>
                                        </Row>
                                        <Accordion.Collapse onEnter={() => { setToggle(Icon.ChevronCompactUp) }} onExited={() => { setToggle(Icon.ChevronCompactDown) }} eventKey='0'>
                                            <Row>
                                                <Col className='text-justify'>
                                                    <p>A partir de la implementación del Sistema de Gestión de Calidad en 2014, CAJIGA y ASOCIADOS logró obtener el Certificado IQNet el cuál acredita a nivel mundial la certificación en la norma ISO, dicho logró nos dio la pauta para obtener clientes en España brindándoles nuestros servicios de cobranza tal es el caso de la Fintech Cream Finance.</p>
                                                </Col>
                                            </Row>
                                        </Accordion.Collapse>
                                    </Container>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className='text-center small'>
                                    <Container fluid className='p-0'>
                                        <Row>
                                            <Col className='p-4'>
                                                <img src={sige} alt='cert' className='rounded shadow-img' style={{ height: '100px' }} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <h4>ISO</h4>
                                                <h4>9001:2008</h4>
                                            </Col>
                                        </Row>
                                        <Accordion.Collapse eventKey='0'>
                                            <Row>
                                                <Col className='text-justify'>
                                                    <p>A principios de 2014 obtuvimos la certificación en ISO 9001:2008  brindando a nuestros clientes la garantía de profesionalización de servicios con un Sistema de Calidad en los servicios otorgados además de cumplir con las exigencias comerciales y sociales en el tratamiento de la información. En Julio 2019 reafirmamos satisfactoriamente dicha certificación.</p>
                                                </Col>
                                            </Row>
                                        </Accordion.Collapse>
                                    </Container>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Accordion.Toggle as={toggle} size='50' fill='#41b7c4' eventKey='0' className='cursor-pointer' />
                                </Col>
                            </Row>
                        </Container>
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
}

export default Certifications
