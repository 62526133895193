import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ContactOther from '../Contact/ContactOther.js';
import ContactMail from '../Contact/ContactMail';



class Contact extends React.Component {

    render() {
        
        const headerParams = {
            smallTitle: 'Estamos para servirte',
            bigTitle: 'Tambien nos puedes encontrar',
            align: 'text-left',
        }

        return (
            
            <Container fluid className='mt-5 mb-5' className="topContainer">
                <Row>              
                    <Col lg={7} md={7} sm={12} xs={12} className='m-0 p-0'>
                        <ContactMail />
                    </Col>
                    <Col lg={5} md={5} sm={12} xs={12} className='m-0 p-0' >
                        <ContactOther header={headerParams} />
                    </Col>
                </Row>
            </Container>
        );
    }
   
}

export default Contact;