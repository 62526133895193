import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';
import * as Icon from 'react-bootstrap-icons';

import './Services.css';
import SectionHeader from '../SectionHeader/SectionHeader';

import CustomerSupport from '../../images/services/customer-support.png';
import Admin from '../../images/services/Administrativa.jpg';
import Dict from '../../images/services/DictaminacionDeCuentas.jpg'
import Judicial from '../../images/services/Judicial.jpg'
import Litigio from '../../images/services/LitigioHipotecario.jpg'
import Preventiva from '../../images/services/Preventiva.jpg'
import Recuperacion from '../../images/services/RecuperacionAutomoviles.jpg'
import Verificacion from '../../images/services/VerificacionTelefonicaDomiciliaria.jpg'


function AccordionToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);

    const toggle = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <div onMouseOver={isCurrentEventKey ? () => { } : toggle} >
            {children}
        </div>
    );
}

const Services = () => {
    const collectionHeaderParams = {
        smallTitle: 'Servicios',
        bigTitle: 'Cobranza',
        align: 'text-left'
    }

    const customerSupportHeaderParams = {
        bigTitle: 'Atención a clientes',
        bigTitleColor: 'whiteTitle'
    }

    const techHeaderParams = {
        smallTitle: 'Herramientas de operación',
        bigTitle: 'Servicios tecnológicos'
    }

    return (
        <Container fluid className='pl-0 pr-0 zoom-img' style={{ paddingTop: '10%' }}>
            <Row className='m-0'>
                <Col lg={3} md={12} sm={12} xs={12} className='p-0'>
                    <Container fluid>
                        <Row>
                            <Col className='mt-5'>
                                <SectionHeader {...collectionHeaderParams} />
                            </Col>
                        </Row>
                        <Row className='p-3'>
                            <Col>
                                <p>Más de <b className='h5'>32 años</b> de experiencia nos respaldan</p>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col lg={9} md={12} sm={12} xs={12} className='p-0'>
                    <Accordion>
                        <Container fluid>
                            <Row className='d-flex align-items-end text-center'>
                                <>
                                    <Col lg={4} md={4} sm={6} xs={12} className='p-1 '>
                                            <Container fluid className='d-flex justify-content-center align-items-end rounded'
                                                style={{
                                                    height: 400,
                                                    backgroundColor: '#1b2642',
                                                    backgroundImage: `url(${Preventiva})`,
                                                    backgroundPosition: 'center', backgroundSize: 'cover'
                                                }}>
                                                <Row className='mt-auto text-white card-transbox'>
                                                    <Col lg={12} md={12} sm={12} xs={12} style={{ zIndex: 1 }}>
                                                        <img className='imgJoinTeam' src={Preventiva} alt='img' />
                                                        <h5><Icon.BookmarkCheckFill className='m-1' />Preventiva</h5>
                                                    </Col>
                                                    <Col style={{ zIndex: 1 }}>
                                                            <p>Se ejecuta mediante envío de mensajes de texto SMS, mensajes de Voz, correos electrónicos, inclusive llamadas telefónicas.</p>
                                                    </Col>
                                                </Row>
                                            </Container>
                                    </Col>
                                    <Col lg={4} md={4} sm={6} xs={12} className='p-1'>
                                        <AccordionToggle eventKey='1'>
                                            <Container fluid className='d-flex justify-content-center align-items-end rounded' style={{
                                                height: 250,
                                                backgroundColor: '#41b7c4',
                                                backgroundImage: `url(${Admin})`,
                                                backgroundPosition: 'center', backgroundSize: 'cover'
                                            }}>
                                                <Row className='mt-auto text-white card-transbox'>
                                                    <Col lg={12} md={12} sm={12} xs={12} style={{ zIndex: 1 }}>
                                                        <h5><Icon.BookmarkCheckFill className='m-1' />Administrativa</h5>
                                                    </Col>
                                                    <Col style={{ zIndex: 1 }}>
                                                        <Accordion.Collapse eventKey='1'>
                                                            <p>Proceso sistemático de evaluación, seguimiento y control de las cuentas por cobrar con el fin de lograr que los pagos se realicen de manera oportuna y efectiva.</p>
                                                        </Accordion.Collapse>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </AccordionToggle>
                                    </Col>
                                    <Col lg={4} md={4} sm={6} xs={12} className='p-1'>
                                        <AccordionToggle eventKey='2'>
                                            <Container fluid className='d-flex justify-content-center align-items-end rounded' style={{
                                                height: 250,
                                                backgroundColor: '#d3d3d3',
                                                backgroundImage: `url(${Judicial})`,
                                                backgroundPosition: 'center', backgroundSize: 'cover'
                                            }}>
                                                <Row className='mt-auto text-white card-transbox'>
                                                    <Col lg={12} md={12} sm={12} xs={12} style={{ zIndex: 1 }}>
                                                        <h5><Icon.BookmarkCheckFill className='m-1' />Extrajudicial</h5>
                                                    </Col>
                                                    <Col style={{ zIndex: 1 }}>
                                                        <Accordion.Collapse eventKey='2'>
                                                            <p>Etapa previa a la cobranza judicial, ya que son todos los esfuerzos que realiza una empresa para cobrar su dinero a partir del primer día de mora del deudor.</p>
                                                        </Accordion.Collapse>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </AccordionToggle>
                                    </Col>
                                </>
                                <>
                                    <Col lg={4} md={4} sm={6} xs={12} className='p-1'>
                                        <AccordionToggle eventKey='3'>
                                            <Container fluid className='d-flex justify-content-center align-items-end rounded' style={{
                                                height: 250,
                                                backgroundColor: '#d3d3d3',
                                                backgroundImage: `url(${Admin})`,
                                                backgroundPosition: 'center', backgroundSize: 'cover'
                                            }}>
                                                <Row className='mt-auto text-white card-transbox'>
                                                    <Col lg={12} md={12} sm={12} xs={12} style={{ zIndex: 1 }}>
                                                        <h5><Icon.BookmarkCheckFill className='m-1' />Judicial</h5>
                                                    </Col>
                                                    <Col style={{ zIndex: 1 }}>
                                                        <Accordion.Collapse eventKey='3'>
                                                            <p></p>
                                                        </Accordion.Collapse>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </AccordionToggle>
                                    </Col>
                                    <Col lg={4} md={4} sm={6} xs={12} className='p-1'>
                                        <AccordionToggle eventKey='4'>
                                            <Container fluid className='d-flex justify-content-center align-items-end rounded' style={{
                                                height: 250,
                                                backgroundColor: '#41b7c4',
                                                backgroundImage: `url(${Litigio})`,
                                                backgroundPosition: 'center', backgroundSize: 'cover'
                                            }}>
                                                <Row className='mt-auto text-white card-transbox'>
                                                    <Col lg={12} md={12} sm={12} xs={12} style={{ zIndex: 1 }}>
                                                        <h5><Icon.BookmarkCheckFill className='m-1' />Litigio hipotecario</h5>
                                                    </Col>
                                                    <Col style={{ zIndex: 1 }}>
                                                        <Accordion.Collapse eventKey='4'>
                                                            <p></p>
                                                        </Accordion.Collapse>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </AccordionToggle>
                                    </Col>
                                    <Col lg={4} md={4} sm={6} xs={12} className='p-1'>
                                        <AccordionToggle eventKey='5'>
                                            <Container fluid className='d-flex justify-content-center align-items-end rounded' style={{
                                                height: 250,
                                                backgroundColor: '#1b2642',
                                                backgroundImage: `url(${Dict})`,
                                                backgroundPosition: 'center', backgroundSize: 'cover'
                                            }}>
                                                <Row className='mt-auto text-white card-transbox'>
                                                    <Col lg={12} md={12} sm={12} xs={12} style={{ zIndex: 1 }}>
                                                        <h5><Icon.BookmarkCheckFill className='m-1' />Dictaminación de cuentas incobrables</h5>
                                                    </Col>
                                                    <Col style={{ zIndex: 1 }}>
                                                        <Accordion.Collapse eventKey='5'>
                                                            <p></p>
                                                        </Accordion.Collapse>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </AccordionToggle>
                                    </Col>
                                </>
                                <Container fluid>
                                    <Row className='d-flex align-items-start'>
                                        <Col lg={8} md={8} sm={6} xs={12} className='p-1'>
                                            <AccordionToggle eventKey='6'>
                                                <Container fluid className='d-flex justify-content-center align-items-end rounded' style={{
                                                    height: 250,
                                                    backgroundColor: '#1b2642',
                                                    backgroundImage: `url(${Verificacion})`,
                                                    backgroundPosition: 'center', backgroundSize: 'cover'
                                                }}>
                                                    <Row className='mt-auto text-white card-transbox'>
                                                        <Col lg={12} md={12} sm={12} xs={12} style={{ zIndex: 1 }}>
                                                            <h5><Icon.BookmarkCheckFill className='m-1' />Verificación telefónica y domiciliaria</h5>
                                                        </Col>
                                                        <Col style={{ zIndex: 1 }}>
                                                            <Accordion.Collapse eventKey='6'>
                                                                <p></p>
                                                            </Accordion.Collapse>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </AccordionToggle>
                                        </Col>
                                        <Col lg={4} md={4} sm={6} xs={12} className='p-1'>
                                            <AccordionToggle eventKey='7'>
                                                <Container fluid className='d-flex justify-content-center align-items-end rounded' style={{
                                                    height: 400,
                                                    backgroundColor: '#d3d3d3',
                                                    backgroundImage: `url(${Recuperacion})`,
                                                    backgroundPosition: 'center', backgroundSize: 'cover'
                                                }}>
                                                    <Row className='mt-auto text-white card-transbox'>
                                                        <Col lg={12} md={12} sm={12} xs={12} style={{ zIndex: 1 }}>
                                                            <h5><Icon.BookmarkCheckFill className='m-1' />Recuperación de automóviles y daciones en pago</h5>
                                                        </Col>
                                                        <Col style={{ zIndex: 1 }}>
                                                            <Accordion.Collapse eventKey='7'>
                                                                <p></p>
                                                            </Accordion.Collapse>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </AccordionToggle>
                                        </Col>
                                    </Row>
                                </Container>
                            </Row>
                        </Container>
                    </Accordion>
                </Col>
            </Row>
            <Row className='m-0'>
                <Col className='p-0'>
                    <Container fluid className='mt-5 mb-5'>
                        <Row>
                            <Col lg={6} md={6} sm={12} xs={12} className='m-0 p-0' >
                                <Container fluid className='pb-5 text-white' style={{ backgroundColor: '#1b2642' }}>
                                    <Row>
                                        <Col className='mt-5'>
                                            <SectionHeader {...customerSupportHeaderParams} />
                                        </Col>
                                    </Row>
                                    <Row className='mt-5'>
                                        <Col className='d-flex justify-content-center'>
                                            <ul className='p-0' style={{ listStyleType: 'none' }}>
                                                <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-2' />Atención de quejas</li>
                                                <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-2' />Encuestas</li>
                                                <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-2' />Retención de clientes</li>
                                            </ul>
                                        </Col>
                                    </Row>
                                    <Row className='mt-5'>
                                        <Col className='d-flex justify-content-center'>
                                            <div className='d-flex justify-content-center align-items-center rounded-circle bg-white' style={{ width: 130, height: 130 }}>
                                                <img src={CustomerSupport} alt='icon' height={130} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className='m-0 p-0'>
                                <Container fluid>
                                    <Row>
                                        <Col className='mt-5'>
                                            <SectionHeader {...techHeaderParams} />
                                        </Col>
                                    </Row>
                                    <Row className='mt-4'>
                                        <Col className='d-flex justify-content-center p-0'>
                                            <ul className='p-0' style={{ listStyleType: 'none', listStylePosition: 'outside' }}>
                                                <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-2' />PBX, Telefonía SIP</li>
                                                <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-2' />Marcador automático</li>
                                                <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-2' />Sistema de seguridad CCTV</li>
                                                <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-2' />Accesos biométricos de control</li>
                                                <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-2' />Sistema de geolocalización vía celular</li>
                                                <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-2' />Envío de notificaciones y carteos masivos</li>
                                                <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-2' />Envíos masivos de mensajes vía SMS</li>
                                                <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-2' />Envíos masivos de correos electrónicos</li>
                                                <li><Icon.CheckCircleFill style={{ color: "#41b7c4" }} className='m-2' />Blaster</li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}

export default Services;
