import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import * as Icon from 'react-bootstrap-icons';

import Error404 from '../../images/error/404-error.png';
import SectionHeader from '../SectionHeader/SectionHeader';

const NotFound = props => {
    const { setNotFound } = props;

    useEffect(() => {
        setNotFound(true);

        return () => {
            setNotFound(false);
        }
    });

    return (
        <Container fluid className='text-center' style={{ paddingTop: '10%' }}>
            <Row className='p-0 mb-5'>
                <Col>
                    <SectionHeader smallTitle='Oops...' bigTitle='Página no encontrada' />
                </Col>
            </Row>
            <Row className='p-0 mt-5 mb-5'>
                <Col className='d-flex justify-content-center'>
                    <div className='d-flex justify-content-center align-items-center rounded-circle' style={{ width: 240, height: 240, backgroundColor: '#1b2642' }} >
                        <img src={Error404} alt='icon' height={230} />
                    </div>
                </Col>
            </Row>
            <Row className='p-0 mt-5 mb-5'>
                <Col>
                    <Link to="/"><Button variant='info' className="m-2"><Icon.ArrowLeftCircleFill className='mr-2' />Regresar al inicio</Button></Link>
                </Col>
            </Row>
        </Container>
    )
}

export default NotFound
