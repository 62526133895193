import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import logo from '../../images/logos/c&a-text.png';

import './Menu.css';


const Menu = () => {
    const location = useLocation();
    const [opacity, setOpacity] = useState();

    useEffect(() => {
        const toTop = () => {
            window.scrollTo(0, 0);
        }

        toTop();

        return () => { }
    }, [location.pathname]);

    useEffect(() => {
        const settingOpacity = () => {
            if (location.pathname === '/') {
                setOpacity(0);

                window.onscroll = () => {
                    let opacityCal = 0 + (window.scrollY / 700);

                    setOpacity(opacityCal);
                }
            } else {
                window.onscroll = () => { }
                setOpacity(1);
            }
        }

        settingOpacity();

        return () => { }
    }, [location.pathname]);

    return (
        <Container fluid className='p-0 menuContainer' style={{ backgroundColor: `rgba(52, 58, 64, ${opacity})` }}>
            <Row className='m-0'>
                <Col>
                    <Navbar collapseOnSelect expand='lg' variant='dark'>
                        <Navbar.Brand as={Link} to='/' >
                            <img
                                src={logo}
                                height={60}
                                className='logo d-inline-block align-top'
                                alt='logo'
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                        <Navbar.Collapse id='responsive-navbar-nav' className='justify-content-end'>
                            <Nav defaultActiveKey='/' activeKey={location.pathname} className='optionButton'>
                                <Nav.Link as={Link} to='/' eventKey='/'>INICIO</Nav.Link>
                                <Nav.Link as={Link} to='/about-us' eventKey='/about-us'>NOSOTROS</Nav.Link>
                                <Nav.Link as={Link} to='/services' eventKey='/services'>SERVICIOS</Nav.Link>
                                <Nav.Link as={Link} to='/contact' eventKey='/contact'>CONTACTO</Nav.Link>
                                <Nav.Link as={Link} to='/photo-gallery' eventKey='/photo-gallery'>GALERÍA</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Col>
            </Row>
        </Container>
    )
}

export default Menu;